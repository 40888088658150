<template>
    <ConfirmDialog ref="confirmDialog"/>
    <TaskPageWrapper task-type="Contract" @formDirtyChange="formDirty = $event"/>
</template>

<script>
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import TaskPageWrapper from "@/components/TaskPages/TaskPageWrapper.vue";
import unsavedChanges from "@/mixin/unsavedChanges";

export default {
    name: 'Contracts',
    components: {
        TaskPageWrapper,
        ConfirmDialog,
    },
    mixins: [unsavedChanges],
    inject: ['toast'],
    async beforeRouteLeave() {
        return this.triggerUnsavedChangesCheck(this.formDirty);
    },
    data() {
        return {
            formDirty: false
        }
    }
}
</script>
